import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import SuspenseFallback from './components/suspense';
import Middlewares from './middleware';
import { BrowserRouter } from 'react-router-dom';
import ErrorPage from './components/error-page';
import AppRoutes from './routes';

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Middlewares>
        <BrowserRouter>
          <Suspense fallback={<SuspenseFallback />}>
            <AppRoutes />
          </Suspense>
        </BrowserRouter>
      </Middlewares>
    </ErrorBoundary>
  );
};

export default App;
