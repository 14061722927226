import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { DAppProvider } from '@usedapp/core';
import retry from '../utils/index';

const HomePage = lazy(() => retry(() => import('../pages/home')));
const MintPage = lazy(() => retry(() => import('../pages/mint')));
const RoyaltiesPage = lazy(() => retry(() => import('../pages/staking')));
const BreedPage = lazy(() => retry(() => import('../pages/breeding')));

const AppRoute = () => {
  return (
    <DAppProvider config={{}}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/mint' element={<MintPage />} />
        <Route path='/staking' element={<RoyaltiesPage />} />
        <Route path='/breed' element={<BreedPage />} />
      </Routes>
    </DAppProvider>
  );
};

export default AppRoute;
